/* App.css */
body {
  font-family: 'Poppins', sans-serif;
  /* Apply Poppins to the entire body */
}

/* Weight and style variations for Poppins */
.poppins-thin {
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-weight: 900;
  font-style: italic;
}

/* Add more specific styles as needed */
@media print {
  body * {
    visibility: hidden;
  }

  .print-container,
  .print-container * {
    visibility: visible;
  }

  .print-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

/* Add this CSS to your stylesheet */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 270px;
  /* Adjust width as needed */
  background-color: #ffffff;
  overflow-y: auto;
  /* Enable scrolling if content exceeds sidebar height */
  border-right: 1px solid #dddddd;
  /* Optional: Add a border */
}

.main-content {
  margin-left: 270px;
  /* Adjust to match sidebar width */


  overflow-y: auto;
  /* Enable scrolling */
}

.underline-green {
  text-decoration-color: green;
  /* or your desired shade of green */
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  background: white;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}

@keyframes shine {
  0% {
    transform: translateX(-150%);
  }

  100% {
    transform: translateX(150%);
  }
}
.animate-shine {
  animation: shine 2s infinite;
}
.custom-confirm-button {
  background-color: #C6A26F !important;
  /* Set button background color */
  color: white !important;
  /* Set button text color */
  border: none !important;
  width: 200px;
  /* Optional: Remove button border */
}

.custom-confirm-button:hover {
  background-color: #b49357 !important;
  width: 200px;
  /* Optional: Change color on hover */
}